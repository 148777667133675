import { useState } from "react";
import { callFunction } from "../../api/firebase";
import "./balance-account.scss"

interface iBalanceAccountProps {
    label: string,
    func: string,
}

interface iSyncResponse {
    posted : {
        data: {
            transaction_ids: string[]
            duplicate_import_ids: string[]
        }
    }
}
interface iResults {
   imported: number
   skipped: number
}


const BalanceAccount =  (props: iBalanceAccountProps)=> {

    const [results, setResults] = useState<iResults|null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false)

    const doSync = async () => {

        setLoading(true)
        setError(false)
        setResults(null)
        try {
            const resp = await callFunction<null, iSyncResponse>(props.func)
            setLoading(false)
            setResults({
                imported: resp.data.posted.data.transaction_ids.length,
                skipped: resp.data.posted.data.duplicate_import_ids.length,
            })
        } catch (e) {
            setLoading(false)
            setError(true)
            console.error(e)
        }
    }

    return <div className="balance-account">
        {!loading && <button onClick={doSync}>Push {props.label}</button>}
        {loading && <div>Sync in progress</div>}
        {results && <div className="balance-results">
            <div>Imported: {results.imported}</div>
            <div>Skipped: {results.skipped}</div>
            <button onClick={()=>setResults(null)}>Close</button>
        </div>}
        {error && <div>An error occurred</div>}
    </div>
}

export default BalanceAccount