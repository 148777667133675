
import BalanceAccount from '../balance-account/balance-account';
import data from '../../accounts.json'
import PairInfo from '../pair-info/pair-info';

import './accounts-list.scss';

const AccountsList = () => {

    return <div className="accounts-list">
        <header>Accounts to Balance</header>
        <div className="accounts-list-inner">
            {data.pairs.map((pair, index) =>
                <div key={index} className="account-pair">
                    <div className="pair-header">{pair.left?.label} -- {pair.right?.label}</div>
                    <div className="pair-body">
                        <BalanceAccount label={pair.left.label} func={pair.left.endpoint} />
                        <PairInfo endpoint={pair.infoEndpoint} />
                        <BalanceAccount label={pair.right.label} func={pair.right.endpoint} />
                    </div>
                </div>

            )}
        </div>
    </div>
}

export default AccountsList