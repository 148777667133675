{
    "pairs": [
        {
            "left": {
                "endpoint": "hilton2Shared",
                "label": "Hiton"
            },
            "right": {
                "endpoint": "shared2Hilton",
                "label": "Shared"
            },
            "infoEndpoint": "hiltonSharedInfo"
        },
        {
            "left": {
                "endpoint": "hilton2Rachel",
                "label": "Hilton"
            },
            "right": {
                "endpoint": "rachel2Hilton",
                "label": "Rachel"
            },
            "infoEndpoint": "hiltonRachelInfo"
        },
        {
            "left": {
                "endpoint": "rachel2Shared",
                "label": "Rachel"
            },
            "right": {
                "endpoint": "shared2Rachel",
                "label": "Shared"
            },
            "infoEndpoint": "rachelSharedInfo"
        }
    ],
    "buttons": []
}