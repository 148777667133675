// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA5EByHkvTKVwNjLEJvSPkASf0SSaV5RNM",
  authDomain: "household-budget-29655.firebaseapp.com",
  projectId: "household-budget-29655",
  storageBucket: "household-budget-29655.appspot.com",
  messagingSenderId: "1015147534629",
  appId: "1:1015147534629:web:cebf17810a3e69398f22cc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const functions = getFunctions();
if (process.env.NODE_ENV !== 'production') {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}


export default app;

export const callFunction = async <request, response>(name: string) => {
    const func = httpsCallable<request, response>(functions, name);
    return func()
}