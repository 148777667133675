import { useState } from "react";
import { callFunction } from "../../api/firebase";

import "./pair-info.scss"

interface iPairInfoProps {
    endpoint: string
}

interface iCompareData {
    uncleared: object[];
    cleared: object[];
    balance: number;
}

interface iCompareResponse {
    left: iCompareData;
    right: iCompareData;
}



const PairInfo = ({endpoint}: iPairInfoProps)=> {
    const [results, setResults] = useState<iCompareResponse|null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false)

    const getData = async () => {

        setLoading(true)
        setError(false)
        setResults(null)
        try {
            const resp = await callFunction<null, iCompareResponse>(endpoint)
            setLoading(false)
            setResults(resp.data)
        } catch (e) {
            setLoading(false)
            setError(true)
            console.error(e)
        }
    }
    return <div className="pair-info">
        {!results && <button className="compare-button" onClick={getData}>Compare</button>}
        {loading && <div>Fetching data...</div>}
        {results && <div className="compare-results">
            <div className="results-entry">
                <div className="results-row">
                    <div>${results.left.balance/1000}</div>
                    <div>Balance</div>
                    <div>${results.right.balance/1000}</div>
                </div>
                <div className="results-row">
                    <div>{results.left.uncleared.length}</div>
                    <div>Uncleared</div>
                    <div>{results.right.uncleared.length}</div>
                </div>
                <div className="results-row">
                    <div>{results.left.cleared.length}</div>
                    <div>Cleared</div>
                    <div>{results.right.cleared.length}</div>
                </div>
            </div>

            <button onClick={()=>setResults(null)}>Close</button>
        </div>}
        {error && <div>An error occurred</div>}

    </div>
}
export default PairInfo