import './App.scss';
import PaystubUpload from './components/paystub-upload/paystub-upload';
import AccountsList from './components/accounts-list/accounts-list';

function App() {
  return (
    <div className="App">
      <AccountsList/>
      <PaystubUpload/>
    </div>
  );
}

//{/* <BalanceAccount key={index} label={button.label} func={button.endpoint}/> */}
export default App;
