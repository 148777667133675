import { FC, useState } from 'react';
import { PAYSTUB_API_URL } from '../../constants';

import './paystub-upload.scss';

const PaystubUpload: FC = () => {
    const [text, setText] = useState('');
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false)

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };

    const handleSubmit = () => {
        // Specify the URL where you want to upload the text contents
        setLoading(true)
        setError(false);
        setSuccess(false);

        const url = PAYSTUB_API_URL;
        if (!url) {
            setError(true);
            throw new Error('PAYSTUB_API_URL is not defined');
        }

        // Send a POST request with the text contents
        fetch(url, {
            method: 'POST',
            body:  text,
            headers: {
                'Content-Type': 'text/plain',
            },
        })
        .then(data => {
            if (!data.ok) {
                throw new Error('Error in sending paystub');
            }
            // Handle the response from the server
            console.log(data);
            setText('')
            setSuccess(true)
        })
        .catch(error => {
            // Handle any errors that occur during the request
            setError(true)
            console.error(error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className="paystub-upload">
            <div className="header">Paystub upload</div>
            <textarea value={text} onChange={handleTextChange} />
            {!loading && <button disabled={text.length === 0} onClick={handleSubmit}>Submit</button>}
            {loading && <div>Fetching data...</div>}
            {error && <div>Error in sending paystub</div>}
            {success && <div>Paystub sent successfully</div>}
        </div>
    );
};

export default PaystubUpload;
